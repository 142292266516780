import Image, { ImageProps } from "next/legacy/image";

export type ContentfulImage = {
  url: string;
  width: number;
  height: number;
  description?: string;
};
export const ContentfulImage: React.FC<{
  image: ContentfulImage;
  className?: string;
  layout?: ImageProps["layout"];
}> = ({ image, className, layout = "responsive" }) => {
  if (!image || !image?.url) return null;
  return (
    <Image
      className={className}
      src={image.url}
      width={layout === "fill" ? undefined : image.width}
      height={layout === "fill" ? undefined : image.height}
      alt={image?.description ?? ""}
      layout={layout}
    />
  );
};
