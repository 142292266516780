import styles from "./Video.module.css";
import MuxVideo from "@mux/mux-player-react";

import NextImage, { StaticImageData } from "next/legacy/image";
import { useAccount } from "wagmi";
import { ContentfulImage } from "../Contentful/Image";
import cx from "classnames";

type VideoProps = {
  img?: StaticImageData;
  videoId?: string;
  contentfulImagesrc?: ContentfulImage;
  className?: string;
};
export const Video: React.FC<VideoProps> = props => {
  const { img, videoId, contentfulImagesrc, className } = props;
  if (Object.values(props).length > 2) {
    throw new Error("only one prop allowed for video");
  }
  const { address } = useAccount();
  return (
    <div className={cx(styles.video, className)}>
      {img && <NextImage layout="responsive" alt={"title"} src={img} />}
      {contentfulImagesrc && <ContentfulImage image={contentfulImagesrc} />}

      {videoId && (
        <MuxVideo
          style={{ height: "100%", maxWidth: "100%" }}
          playbackId={videoId}
          metadata={{
            video_id: "Homepage Running",
            video_title: "Homepage Running",
            viewer_user_id: address ?? "unknown"
          }}
          streamType="on-demand"
          envKey="ecouuj70pqac6n54q65p027j0"
        />
      )}
    </div>
  );
};
