import Link from "next/link";
import { PropsWithChildren } from "react";

export const PreviewWrapper: React.FC<
  PropsWithChildren<{ preview: boolean }>
> = ({ children, preview }) => {
  return (
    <>
      {preview && (
        <div className="fixed bottom-5 right-5 z-50 p-4 bg-coinage-orange text-coinage-white">
          This page is a preview.{"  "}
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a href="/api/exit-preview" className="underline ">
            Click here{"  "}
          </a>
          to exit preview mode.
        </div>
      )}
      {children}
    </>
  );
};
