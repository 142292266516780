import { PropsWithChildren } from "react";
import cx from "classnames";
import dynamic from "next/dynamic";

const DynamicText = dynamic(() => import("./Dynamic"), {
  ssr: false
});

export interface TextProps
  extends Omit<
    React.ComponentPropsWithoutRef<"h1">,
    "onAnimationStart" | "onDragStart" | "onDragEnd" | "onDrag"
  > {
  textStyle: "display1" | "display2";
}

export const FadeText: React.FC<PropsWithChildren<TextProps>> = ({
  children,
  className,
  textStyle,
  ...rest
}) => {
  const textClassName = cx(textStyle, className);

  return (
    <DynamicText textStyle={textStyle} className={textClassName} {...rest}>
      {children}
    </DynamicText>
  );
};
